<template>
  <footer class="mt-3 new_footer_area" :style="`background-image: url('${imageDomain}/assets/img/newfooterbg.jpg?speedsize=w_100');`">
    <div class="footer_body" v-if="device === 'desktop'">
      <div class="container">
        <div class="row">
          <div class="col-6 col-sm-12" :class="`col-md-${pageCount1 < 7 ?  Math.round(12/pageCount1) : 2}`" v-for="(cat, inx) in pageList1" :key="inx">
            <footer-category-one-item :dataByCategory="cat"/>
          </div>
        </div>
      </div>
    </div>

    <div class="footer_body" v-if="pageCount2 && device === 'desktop'">
      <div class="container">
        <div class="row">
          <div class="col-6 col-sm-12" :class="`col-md-${12/pageCount2}`" v-for="(cat, inx) in pageList2" :key="inx">
            <footer-category-one-item :dataByCategory="cat"/>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_body" v-if="device === 'mobile'">
      <div class="container">
        <footer-collapse v-for="(item, inx) in pageList" :key="inx" :oneCategoryItem="item" :index="inx"/>
      </div>
    </div>

    <div class="footer_body">
      <div class="container">
        <div class="row">
          <div class="col-6 col-sm-12 col-md-3">
            <div class="footer">
              <div class="footer_logo"><img :src="`${imageDomain}/assets/img/logo.png?speedsize=w_300`" alt="logo" title=""  width="300" height="66"/></div>
              <h5>{{$t("footer.follow-us-on")}}</h5>
              <ul class="footer_social">
                  <li><a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-x-twitter"></i></a></li>
                  <li><a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href="https://g.co/kgs/ApYvyz" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-google"></i></a></li>
                  <li><a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-instagram"></i></a></li>
                  <!-- <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li> -->
                  <!-- <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li> -->
              </ul>
            </div>
          </div>
          <div class="col-6 col-sm-12 col-md-9">
            <div class="footer row">
              <div class="col-12 col-sm-6 col-md-6 text-center show-message form-group">{{$t("footer.member-login-alert")}}</div>
              <div class="col-12 col-sm-6 col-md-4 text-center">
                <member-register/>
              </div>
              <div class="col-12 col-sm-6 col-md-2 text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="copyright_area"><p>{{ $t("footer.all-reserved-title", {year: new Date().getFullYear(), agencyName: whiteLabel.name}) }}</p></div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'Footer',
  mixins: [imageUrlMixin],
  components: {
    MemberRegister: () => import('@/components/atoms/MemberRegister'),
    FooterCategoryOneItem: () => import('./FooterCategoryOneItem'),
    FooterCollapse: () => import('./FooterCollapse'),
  },
  computed: {
    ...mapGetters({
      marketerId: 'GET_MARKETER_ID',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      device: 'GET_DEVICE',
      lang: 'GET_LANGUAGE',
      footerPagesList: 'GET_FOOTER_PAGES_LIST',
      footerCategoryList: 'GET_FOOTER_CATEGORY_LIST',
    }),
    pageList() {
      const { footerPagesList, footerCategoryList, firstShowingCount } = this;
      if (!footerPagesList || !footerCategoryList) return null;

      // sort the category list
      footerCategoryList.sort((a, b) => (a.sort < b.sort ? -1 : 1));

      const list = [];
      const pageList = Object.entries(footerPagesList).filter((item) => (item[1].active && item[0] !== 'AgreementApp'));
      footerCategoryList.forEach((cat) => {
        const subList = [];
        subList[0] = cat.code;
        const itemList = pageList.filter((page) => (page[1].category === cat.code));
        itemList.sort((a, b) => (a[1].sort > b[1].sort ? -1 : 1));
        subList[1] = itemList.slice(0, firstShowingCount);
        subList[2] = itemList.slice(firstShowingCount, itemList.length);
        subList[3] = false;
        list.push(subList);
      });

      return list;
    },
    pageCount() {
      const { pageList } = this;
      if (!pageList) return 0;

      return pageList.length;
    },
    pageCount1() {
      const { pageList1 } = this;
      if (!pageList1) return 0;
      return pageList1.length;
    },
    pageCount2() {
      const { pageList2 } = this;
      if (!pageList2) return 0;
      return pageList2.length;
    },
    pageList1() {
      const { pageList } = this;
      if (!pageList) return [];
      return pageList.slice(0, this.maxCountPerRow);
    },
    pageList2() {
      const { pageList } = this;
      if (!pageList) return [];
      return pageList.slice(this.maxCountPerRow, pageList.length);
    },
    hostData() {
      let currHostData = null;
      if (this.marketerId !== '') {
        currHostData = this.marketerAgencyContent;
      } else if (this.whiteLabel && !this.whiteLabel.forMainSite) {
        currHostData = this.whiteLabel;
      }
      return currHostData;
    },
    facebookLink() {
      const defaultLink = 'https://he-il.facebook.com/flying.co.il/';
      const link = this.hostData?.facebookLink ? `http://www.facebook.com/${this.hostData?.facebookLink.replace('http://www.facebook.com/', '')}` : defaultLink;
      return link;
    },
    instagramLink() {
      const defaultLink = 'https://www.instagram.com/flyingcarpet_il/';
      const link = this.hostData?.instagramLink ? `https://www.instagram.com/${this.hostData?.instagramLink.replace('https://www.instagram.com/', '')}` : defaultLink;
      return link;
    },
    twitterLink() {
      const defaultLink = 'https://twitter.com/@flyingcarpetltd';
      const link = this.hostData?.twitterLink ? `https://twitter.com/${this.hostData?.twitterLink.replace('https://twitter.com/', '')}` : defaultLink;
      return link;
    },
  },
  data() {
    return {
      landingQuery: '',
      firstShowingCount: 8,
      maxCountPerRow: 6,
    };
  },
  mounted() {
    this.landingQuery = window.sessionStorage.getItem('landing-url') ? `${window.location.origin}/${window.sessionStorage.getItem('landing-url')}` : '';
  },
  methods: {
  },
};
</script>
<style>
body.flat-404 footer{
  margin-bottom: 0;
  color: white;
}
body.flat-404 .footer-btm .copy ul li a {
  color: white;
}
</style>
<style scoped>
.show-message{
  font-size: 1.5rem;
  color: white;
}
.link-nDetail .short-links .list-group-item{
  padding: 0;
}
footer .list-group,
.link-nDetail .footer-social ul {
  padding: 0;
}
footer .shortLink-wrapper:not(:last-child){
  border-left: 1px solid white;
}
footer .shortLink-wrapper{
  padding: 0 1.5rem;
}
</style>
